<template>
    <div>
        <h2>ใบกำกับภาษีแบบเต็ม</h2>
        <v-card class="mt-4 px-6 pt-6">
            <v-row>
              <v-col>
                <v-text-field
                outlined
                v-model="datatable.search"
                placeholder="ค้นหา ..."
                dense
                ></v-text-field>
              </v-col>
            </v-row>
        </v-card>
        <v-card class="mt-4">
          <div class="d-flex pa-4">
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  :disabled="datatable.selectedItems.length == 0"
                  icon
                ><v-icon>{{ mdiPrinter }}</v-icon></v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="printMultiple($PRINT_TYPE.TRD)"
                >
                  <v-list-item-title>ใบกำกับภาษีแบบเต็ม</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>

            <v-btn
            class="ml-0 ml-sm-2"
            @click="$router.push({ 'name': 'trd_export' })"
            ><v-icon class="mr-2">{{ mdiExportVariant }}</v-icon>ส่งออก</v-btn>

            <v-btn color="primary" class="ml-auto" @click="$router.push({ 'name': 'trd_create', params: { trd_id: 'create' } })">สร้างใบกำกับภาษีแบบเต็ม</v-btn>
          </div>
          <delay-datatable
            show-select
            :headers="datatable.headers"
            :url="'/document/trd/list'"
            :queryParams="getDatatableQueryParams"
            :options.sync="datatable.options"
            :isSaveState="true"
            @loadState="loadDatatableState"
            v-model="datatable.selectedItems"
            ref="mainDatatable"
          >

          <template v-slot:[`header.data-table-select`]="{ props, on }">
            <v-simple-checkbox
              :value="props.value || props.indeterminate"
              v-on="on"
              :indeterminate="props.indeterminate"
              color="primary"
              :ripple="false"
            />
          </template>

          <template
            v-slot:item.created_at="{ item }"
          >
            {{ item.created_at | timestampToText }}
          </template>

          <template
            v-slot:item.customer="{ item }"
          >
            <div>
              {{ item.customer_name }}
            </div>
            <div>
              {{ item.customer_taxid }}
            </div>
            <div>
              {{ item.customer_address }}
            </div>
            <div>
              {{ item.customer_tel }}
            </div>
          </template>

          <template
            v-slot:item.value="{ item }"
          >
            {{ item.order.value | formatNumber }}
          </template>

          <template
            v-slot:item.actions="{ item }"
          >
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  icon
                >
                  <v-icon>{{ mdiMenu }}</v-icon>
                </v-btn>
              </template>
              <v-list>
                <v-list-item @click="printSingle($PRINT_TYPE.TRD, item.id)">
                  <v-list-item-icon>
                    <v-icon>{{mdiPrinter}}</v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="ml-2">ใบกำกับภาษีแบบเต็ม</v-list-item-title>
                </v-list-item>

                <v-divider v-if="item.status != 'draft'"></v-divider>

                <v-list-item @click="$router.push({name: 'trd_create', params: { trd_id: item.id }})">
                  <v-list-item-icon>
                    <v-icon>
                      {{ $store.getters['auth/GET_ABILITY'].can('update', 'Document') ? mdiPlaylistEdit : mdiEyeOutline }}
                    </v-icon>
                  </v-list-item-icon>
                  <v-list-item-title class="ml-2">ดู / แก้ไข</v-list-item-title>
                </v-list-item>

              </v-list>
            </v-menu>
          </template>
          </delay-datatable>
        </v-card>
    </div>
</template>
<script>
import { prepareAndPrint } from '@/helpers/printing.js'
import { TRD } from '@/classes/print/TRD'
import OrderStatusBar from '@/components/OrderStatusBar.vue'
import ProductImage from '@/components/ProductImage.vue'
import { asyncGet } from '@/helpers/asyncAxios'
import {
  mdiCashCheck,
  mdiCheck,
  mdiCloudUpload,
  mdiDelete,
  mdiExportVariant,
  mdiEyeOutline,
  mdiInformationOutline,
  mdiLinkVariant,
  mdiMenu,
  mdiPlaylistEdit,
  mdiPrinter,
  mdiTruckDeliveryOutline,
} from '@mdi/js'
export default {
  data() {
    return {
      linkReqDialog: {
        isShow: false,
        link: null,
        orderNumber: null,
      },
      datatable: {
        options: {
          page: 1,
          itemsPerPage: 10,
          sortBy: ['created_at'],
          sortDesc: [true],
        },
        selectedStatus: 'all',
        selectedItems: [],
        search: null,
        headers: [
          {
            text: 'เลขที่เอกสาร',
            value: 'trdNumber',
          },
          {
            text: 'เลขที่บิล',
            value: 'order.orderNumber',
          },
          {
            text: 'วันที่สร้าง',
            value: 'created_at',
          },
          {
            text: 'ลูกค้า',
            value: 'customer',
            sortable: false,
          },
          {
            text: 'มูลค่ารวม',
            value: 'value',
            sortable: false,
          },
          {
            text: '#',
            value: 'actions',
            sortable: false,
            align: 'center',
          },
        ],
      },
      mdiEyeOutline,
      mdiPlaylistEdit,
      mdiMenu,
      mdiLinkVariant,
      mdiCloudUpload,
      mdiInformationOutline,
      mdiTruckDeliveryOutline,
      mdiCheck,
      mdiDelete,
      mdiPrinter,
      mdiCashCheck,
      mdiCheck,
      mdiExportVariant,
    }
  },
  computed: {
    getDatatableQueryParams() {
      return {
        selectedStatus: this.datatable.selectedStatus,
        search: this.datatable.search,
      }
    },
  },
  mounted() {
    if (!this.$route.query.trdId) return

    this.printSingle(this.$PRINT_TYPE.TRD, this.$route.query.trdId)
  },
  methods: {
    loadDatatableState({ options, queryParams }) {
      this.datatable = { ...this.datatable, options: { ...options }, ...queryParams }
    },
    isArray(data) {
      return Array.isArray(data)
    },
    async printMultiple($type) {
      this.$store.commit('app/SET_GLOBAL_LOADING', true)
      const itemIds = this.datatable.selectedItems.map(item => item.id)

      try {
        const { company, data } = await asyncGet('/document/trd/' + $type, { items: itemIds })

        const orders = data.map(item => new TRD(item, company))

        this.$store.commit('app/SET_PRINT_DATA', {
          type: $type,
          data: orders,
          company: company,
        })

        prepareAndPrint()
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.$store.commit('app/SET_GLOBAL_LOADING', false)
    },
    async printSingle($type, id) {
      this.$store.commit('app/SET_GLOBAL_LOADING', true)

      try {
        const { company, data } = await asyncGet('/document/trd/' + $type, { items: [id] })

        this.$store.commit('app/SET_PRINT_DATA', {
          type: $type,
          data: data.map(item => new TRD(item, company)),
        })

        prepareAndPrint()
      } catch (error) {
        console.log(error)
        this.$root.showCommonDialog('มีปัญหา', error)
      }

      this.$store.commit('app/SET_GLOBAL_LOADING', false)
    },
  },
  components: {
    ProductImage,
    OrderStatusBar,
  },
}
</script>
